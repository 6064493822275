import { Box } from "@mui/material";
import { useEffect, useRef } from "react";
import Container from "./Container";
import InputContainer from "./InputContainer";
export default function Widget(props: any) {

  const disclaimerArray: any = []

  return (
    <div className="ARC_Widget">
      <div className="IandO">
        <div className="inputTd">
          <Box className="InputBox">
            <InputContainer
              request={props.request}
              refresh={props.refresh}
              setRequest={props.setRequest}
              responseObject={props.responseObject}
            ></InputContainer>
          </Box>
        </div>
        {props.responseObject?.output?.containers?.filter((x: any) => x.id === "output").map((x: any) => {
          return (
            <div className="outputContainer" key={x.id}>
              <Container
                disclaimerArray={disclaimerArray}
                apiKey={props.apiKey}
                value={x}
                responseObject={props.responseObject}>
              </Container>
            </div>
          )
        })
        }
      </div>
      <table className="disclaimer">
        <tr>
          {props.responseObject?.output?.containers?.filter((x: any) => x.id === 'output')?.map((x: any) => {
            let i: any = 0;
            return (
              <div key={x.id}>
                {
                  x.items.filter((y: any) => y.disclaimer).map((y: any) => {
                    let stars = "*";
                    for (let j: any = 0; j < i; j++) {
                      stars += "*";
                    }
                    i++;

                    // Function to replace URLs in the disclaimer text with clickable links, excluding a trailing period
                    const createDisclaimerWithLinks = (text: string) => {
                      const urlRegex = /(https?:\/\/[^\s.]+(?:\.[^\s.]+)+)/g; // Allow dots within the URL, but not at the end
                      return text.split(urlRegex).map((part, index) => {
                        if (urlRegex.test(part)) {
                          return <a key={index} href={part} target="_blank" rel="noopener noreferrer">{part}</a>;
                        }
                        return part;
                      });
                    };

                    // Function to remove <a> tags and keep only the URL as clickable link, excluding a trailing period
                    const removeAnchorTags = (text: string) => {
                      const urlRegex = /(https?:\/\/[^\s.]+(?:\.[^\s.]+)+)/g; // Same regex adjustment
                      return text.replace(/<a[^>]*>(.*?)<\/a>/g, '$1').split(urlRegex).map((part, index) => {
                        if (urlRegex.test(part)) {
                          return <a key={index} href={part} target="_blank" rel="noopener noreferrer">{part}</a>;
                        }
                        return part;
                      });
                    };

                    return <div key={i}>{stars + " "}{removeAnchorTags(y.disclaimer)}</div>;
                  })
                }
              </div>
            );
          })}
        </tr>
      </table>
    </div>
  );
}